.templates_tags_tagCategoryManagementButton {
  font-size: 1rem;
  padding: 0.25em 0.75em;
  margin-left: auto;
  background-color: #EFEFEF;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.templates_tags_tagCategoryManagementButton > i {
  color: #333333 !important;
  margin-right: 0.5em;
}
.templates_tags_tagCategoryManagementButton:hover {
  cursor: pointer;
}
