body:not(.fullScreen) .base_modal_modal.container_templates_tags_tagAssignment .window {
  left: 10px;
  top: 10px;
  width: 525px;
  height: 500px;
}
body:not(.fullScreen) .base_modal_modal.container_templates_tags_tagAssignment .window .templates_tags_tagAssignment {
  font-size: 0.875em;
}
body:not(.fullScreen) .base_modal_modal.container_templates_tags_tagAssignment .window .templates_tags_tagAssignment > section.content {
  padding: 0.5rem;
}
body.mobile .base_modal_modal.container_templates_tags_tagAssignment .window {
  width: 98%;
  height: 98%;
  left: 1%;
  top: 1%;
}
body.mobile .base_modal_modal.container_templates_tags_tagAssignment .window .templates_tags_tagAssignment {
  font-size: 0.875em;
}
body.mobile .base_modal_modal.container_templates_tags_tagAssignment .window .templates_tags_tagAssignment > section.content {
  padding: 0.5rem;
}
.templates_tags_tagAssignment {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  color: #333333;
}
.templates_tags_tagAssignment > section {
  padding: 1.5em;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.templates_tags_tagAssignment > section.enhanced {
  flex: 0 1 30%;
  border-right: 1px solid #CCCCCC;
}
.templates_tags_tagAssignment > section.tagsOnly {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
}
.templates_tags_tagAssignment > section.tagsOnly > section {
  padding: 1.5em;
}
.templates_tags_tagAssignment > section.tagsOnly > section.filter {
  border-bottom: 1px solid #CCCCCC;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin: 0.75em 0 0.375em 0;
  padding-bottom: 0.125em;
  border-bottom: 1px solid #CCCCCC;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category:first-child {
  margin: 0 0 0.5em 0;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category > i {
  transition: opacity 0.2s ease-in-out;
  margin-right: 0.25em;
  display: none;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category > i.selected {
  opacity: 0;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category > i.inherited {
  opacity: 0;
  font-size: 0.5em;
  margin-top: -2em;
  margin-left: -1em;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category > i.unselected {
  margin-right: -1em;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category.selected > i.selected {
  opacity: 1;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category.inherited > i.inherited {
  opacity: 1;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category.showOption > i {
  display: unset;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category:hover {
  cursor: pointer;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category:hover i {
  color: #888888;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .category.dirty i {
  color: #888888;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0 0.375em 1.5em;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  padding: 0.125em 0.75em;
  width: 100%;
  text-align: left;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData,
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData:focus,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData:focus,
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData:active,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData:active {
  background-color: transparent;
  border: none;
  outline: none;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData i,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData i {
  transition: opacity 0.2s ease-in-out;
  margin-right: 0.25em;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData i.selected,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData i.selected {
  opacity: 0;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData i:not(.selected),
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData i:not(.selected) {
  margin-right: -1em;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData.selected i.selected,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData.selected i.selected {
  opacity: 1;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData:hover,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData:hover {
  cursor: pointer;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData:hover i,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData:hover i {
  color: #888888;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData.dirty i,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData.dirty i {
  color: #888888;
}
.templates_tags_tagAssignment > section.tagsOnly > section.content > .categoryData.nonMatching,
.templates_tags_tagAssignment > section.tagsOnly > section.content .categoryDataContainer > .categoryData.nonMatching {
  font-size: 0.625em;
  font-style: italic;
}
.templates_tags_tagAssignment.dirtyColorNormal > .tagsOnly > .content > button.categoryData.dirty i,
.templates_tags_tagAssignment.dirtyColorNormal > .tagsOnly > .content .categoryDataContainer > button.categoryData.dirty i {
  color: #333333;
}
