.templates_mediaManagement_image_image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.templates_mediaManagement_image_image > section.head {
  width: 100%;
  border-bottom: 1px solid #CCCCCC;
}
.templates_mediaManagement_image_image > section.head > section.top {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 20px;
  overflow-x: hidden;
}
.templates_mediaManagement_image_image > section.head > section.top > * {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #CCCCCC;
}
.templates_mediaManagement_image_image > section.head > section.top > * h1 {
  margin-top: 0;
}
.templates_mediaManagement_image_image > section.main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.templates_mediaManagement_image_image > section.main > section.left {
  width: calc(100% - 400px);
  height: 100%;
}
.templates_mediaManagement_image_image > section.main > section.left > section.content {
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #FFF;
}
.templates_mediaManagement_image_image > section.main > section.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  height: 100%;
}
.templates_mediaManagement_image_image > section.main > section.right > section.actions {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 1px solid #CCCCCC;
}
.templates_mediaManagement_image_image > section.main > section > section {
  box-sizing: border-box;
  padding: 20px;
  overflow-x: hidden;
  width: 100%;
}
