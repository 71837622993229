.templates_controller_list {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-size: 13px;
}
.templates_controller_list i {
  color: #5B7DAD;
}
.templates_controller_list i:hover {
  color: #47648e;
}
.templates_controller_list > .summary {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.templates_controller_list > ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.templates_controller_list > ol > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em 0.25em 0.25em 0.25em;
  line-height: 1em;
}
.templates_controller_list > ol > li.inactive {
  display: none;
}
.templates_controller_list > ol > li.empty {
  align-items: center;
  border: none;
  margin-top: 1em;
}
.templates_controller_list > ol > li.empty:hover {
  background-color: transparent;
}
.templates_controller_list > ol > li.empty > img {
  margin-top: 2em;
}
.templates_controller_list > ol > li > .top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
.templates_controller_list > ol > li > .top > div.title {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: 4.5em;
  padding-right: 0.5em;
}
.templates_controller_list > ol > li > .top:hover > div.title {
  max-height: 15em;
  transition: max-height 0.5s ease-in-out;
}
.templates_controller_list > ol > li > .top > .controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.375em 0.5em 0.5em 0;
}
.templates_controller_list > ol > li > .top > .controls > div.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.templates_controller_list > ol > li > .top > .controls > div.icon.delete {
  margin-top: 0.75em;
}
.templates_controller_list > ol > li > .top > .controls > div.icon i {
  min-width: 1em;
}
.templates_controller_list > ol > li > .top > .controls > div.icon.edit:hover,
.templates_controller_list > ol > li > .top > .controls > div.icon.delete:hover {
  cursor: pointer;
}
.templates_controller_list > ol > li > .top > .files {
  display: inline-flex;
  align-self: flex-start;
  width: 1em;
  height: auto;
  margin-top: 0.375em;
}
.templates_controller_list > ol > li > .top > .files > ul {
  list-style-type: none;
  padding: 0;
}
.templates_controller_list > ol > li > .top > .files > ul > li {
  margin-top: 0.25em;
}
.templates_controller_list > ol > li > .top > .files > ul > li:first-child {
  margin-top: 0;
}
.templates_controller_list > ol > li > .top > .files > ul > li > a {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  color: #333333;
}
.templates_controller_list > ol > li > .bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.5em;
  border-top: 1px solid #666666;
}
.templates_controller_list > ol > li > .bottom > .footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75em;
  font-style: italic;
  box-sizing: border-box;
  padding-right: 0.25em;
}
.templates_controller_list > ol > li > .bottom > .footer > a,
.templates_controller_list > ol > li > .bottom > .footer > a:focus,
.templates_controller_list > ol > li > .bottom > .footer > a:hover,
.templates_controller_list > ol > li > .bottom > .footer > a:active {
  text-decoration: none;
  color: #5B7DAD;
}
.templates_controller_list > ol > li > .bottom > .footer > a:hover {
  color: #47648e;
}
.templates_controller_list > ol > li:hover > .top i {
  color: #47648e;
}
.templates_controller_list > ol > li.deactivated {
  color: #8F8F8F;
}
.templates_controller_list > ol > li.deactivated > a,
.templates_controller_list > ol > li.deactivated > a:focus,
.templates_controller_list > ol > li.deactivated > a:hover,
.templates_controller_list > ol > li.deactivated > a:active {
  color: #8F8F8F;
}
.templates_controller_list > ol > li.deactivated > .top i,
.templates_controller_list > ol > li.deactivated:hover > .top i {
  color: #8F8F8F;
}
