.templates_controller_filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
}
.templates_controller_filter h1 {
  font-size: 1.3em;
  font-weight: normal;
  color: #000000;
  margin: 0.5em 0;
}
.templates_controller_filter .cols {
  display: flex;
  flex-direction: column;
}
.templates_controller_filter .cols .col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 0 !important;
  margin-bottom: 0.5em;
}
.templates_controller_filter .cols .col:nth-child(1) {
  margin-bottom: 0;
}
.templates_controller_filter .cols .col:nth-child(3) .field {
  width: 100%;
}
.templates_controller_filter .cols .col .field {
  display: flex;
  font-size: 13px;
  flex-direction: row;
  margin-left: 0 !important;
  margin-bottom: 0;
  width: 100% !important;
}
.templates_controller_filter .cols .col .field button {
  margin: 0;
}
.templates_controller_filter .cols .col .field > *:not(.fieldIndependent) {
  width: 100% !important;
}
.templates_controller_filter .cols .col .field > *:not(.fieldIndependent).base_form_elements_label {
  display: none;
}
.templates_controller_filter .cols .col .field input,
.templates_controller_filter .cols .col .field textarea,
.templates_controller_filter .cols .col .field select {
  flex: 1;
}
.templates_controller_filter .cols .col .field .base_form_fields_air-datetime-picker {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.templates_controller_filter .cols .col .field .base_form_fields_air-datetime-picker .datepicker-action-icon {
  font-size: 1.8em;
  margin-left: 0.75em;
}
.templates_controller_filter .cols .col .field .base_form_fields_air-datetime-picker .datepicker-action-icon i {
  margin-left: 0;
}
.templates_controller_filter i {
  color: #5B7DAD;
}
.templates_controller_filter i:hover {
  color: #47648e;
}
.templates_controller_filter button {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #5B7DAD !important;
  color: #EFEFEF !important;
  border-color: #EFEFEF !important;
  margin: 0;
  white-space: nowrap;
  font-size: 13px;
}
.templates_controller_filter button i {
  color: #EFEFEF !important;
  margin-right: 0.5rem;
  font-size: 13px;
}
.templates_controller_filter button:hover {
  background-color: #47648e !important;
  color: #FFFFFF !important;
  border-color: #FFFFFF !important;
}
.templates_controller_filter button:hover i {
  color: #FFFFFF !important;
}
