section.main {
  width: 100%;
  overflow: hidden;
}
section.main > div {
  display: none;
}
section.main > div:last-child {
  display: block;
}
* {
  font-family: "Open Sans", Arial, sans-serif;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #FFFFFF;
  color: #444444;
  border-color: #444444;
}
body.desktop .mobile {
  display: none;
}
body.desktop .desktop {
  display: block;
}
body.mobile {
  font-size: 1vh;
}
body.mobile section.main {
  overflow: auto;
}
body.mobile .phone {
  display: block;
}
body.mobile .desktop {
  display: none;
}
body .appContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 830px;
  margin: 0 auto;
}
body.fullScreen .appContainer {
  max-width: 1024px;
}
div.emptyTemplate {
  display: none !important;
}
.base_modal_modal {
  z-index: 1000;
}
.base_form_media_file_file {
  font-size: 1em;
}
.base_form_relationship .base_tabStrip_tabStrip .tabs .base_tabStrip_tab.item.active,
.base_modal_modal .base_tabStrip_tabStrip .tabs .base_tabStrip_tab.item.active {
  background-color: #efefef;
}
.base_form_relationship .base_tabStrip_tabStrip .tabContents,
.base_modal_modal .base_tabStrip_tabStrip .tabContents {
  height: auto;
}
.base_form_relationship .base_tabStrip_tabStrip .tabContents .base_tabStrip_tabContent,
.base_modal_modal .base_tabStrip_tabStrip .tabContents .base_tabStrip_tabContent {
  border: 1px solid #d4d4d5;
  background-color: #efefef;
}
.base_form_relationship .base_tabStrip_tabStrip .tabContents .base_tabStrip_tabContent button,
.base_modal_modal .base_tabStrip_tabStrip .tabContents .base_tabStrip_tabContent button {
  background-color: #FFF;
}
.base_form_relationship .base_tabStrip_tabStrip .tabContents .base_tabStrip_tabContent button:hover,
.base_modal_modal .base_tabStrip_tabStrip .tabContents .base_tabStrip_tabContent button:hover {
  background-color: #ededed;
}
body.mobile .base_modal_modal {
  font-size: 1rem;
}
body.mobile .base_modal_modal > .window {
  width: 98%;
  height: 98%;
  left: 1%;
  top: 1%;
}
body:not(.fullScreen) .base_modal_modal > .window > section.header {
  height: 3rem;
}
body:not(.fullScreen) .base_modal_modal > .window > section.header .title {
  line-height: 3rem;
}
body:not(.fullScreen) .base_modal_modal > .window > section.body {
  height: calc(100% - 6rem);
}
body:not(.fullScreen) .base_modal_modal > .window > section.body > .base_form_form {
  padding: 0.5rem;
}
body:not(.fullScreen) .base_modal_modal > .window > section.footer {
  height: 3rem;
}
body:not(.fullScreen) .base_modal_modal > .window > section.footer .buttons {
  padding: 0.375rem;
}
