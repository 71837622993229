.templates_mediaManagement_mediaDetailsSummary {
  display: inline-flex;
  flex-direction: row;
  flex: 1 1 100%;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.templates_mediaManagement_mediaDetailsSummary > table {
  flex: 1 1 auto;
  border-spacing: 0;
  border: 0;
}
.templates_mediaManagement_mediaDetailsSummary > table > tbody > tr > td {
  font-size: 0.875em;
}
.templates_mediaManagement_mediaDetailsSummary > table > tbody > tr > td:first-child {
  width: 60px;
  padding-right: 10px;
  font-weight: bold;
  white-space: nowrap;
}
.templates_mediaManagement_mediaDetailsSummary > table > tbody > tr > td:last-child {
  word-break: break-all;
}
.templates_mediaManagement_mediaDetailsSummary > table > tbody > tr:last-child > td:last-child {
  display: block;
  height: 1.3em;
  overflow: hidden;
}
.templates_mediaManagement_mediaDetailsSummary > ul {
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  max-height: 100px;
}
.templates_mediaManagement_mediaDetailsSummary > ul > li {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 1em;
  padding: 2px 0 2px 2em;
}
.templates_mediaManagement_mediaDetailsSummary > ul > li:nth-child(6n + 1) {
  padding: 0 0 2px 2em;
}
.templates_mediaManagement_mediaDetailsSummary > ul > li:nth-child(6n + 6) {
  padding: 2px 0 0 2em;
}
.templates_mediaManagement_mediaDetailsSummary > ul > li > i {
  padding: 0.125em 0 0 0.5em;
}
