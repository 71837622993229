.fi,
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-tagFamily-unselected,
.fi.fi-tagFamily-selected,
.fi.fi-tag-unselected,
.fi.fi-tag-selected,
.fi.fi-filter {
  -moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;display: inline-block;font-style: normal;font-variant: normal;text-rendering: auto;line-height: 1;
}
.fi.fa-pull-left,
.fi.fi-square-o.fa-pull-left,
.fi.fi-map-o.fa-pull-left,
.fi.fi-trash.fa-pull-left,
.fi.fi-circle-o.fa-pull-left,
.fi.fi-gear.fa-pull-left,
.fi.fi-cog.fa-pull-left,
.fi.fi-gears.fa-pull-left,
.fi.fi-cogs.fa-pull-left,
.fi.fi-sign-in.fa-pull-left,
.fi.fi-sign-out.fa-pull-left,
.fi.fi-check.fa-pull-left,
.fi.fi-copy.fa-pull-left,
.fi.fi-save.fa-pull-left,
.fi.fi-error.fa-pull-left,
.fi.fi-arrow-left-stop.fa-pull-left,
.fi.fi-arrow-right-stop.fa-pull-left,
.fi.fi-arrow-up-stop.fa-pull-left,
.fi.fi-arrow-down-stop.fa-pull-left,
.fi.fi-file-o.fa-pull-left,
.fi.fi-file-excel-o.fa-pull-left,
.fi.fi-file-text-o.fa-pull-left,
.fi.fi-file-pdf-o.fa-pull-left,
.fi.fi-picture-o.fa-pull-left,
.fi.fi-user-o.fa-pull-left,
.fi.fi-mediaImage.fa-pull-left,
.fi.fi-mediaFile.fa-pull-left,
.fi.fi-media-pdf.fa-pull-left,
.fi.fi-media-svg.fa-pull-left,
.fi.fi-media-jpg.fa-pull-left,
.fi.fi-media-jpeg.fa-pull-left,
.fi.fi-media-png.fa-pull-left,
.fi.fi-media-gif.fa-pull-left,
.fi.fi-media-doc.fa-pull-left,
.fi.fi-media-docx.fa-pull-left,
.fi.fi-media-txt.fa-pull-left,
.fi.fi-tagFamily-unselected.fa-pull-left,
.fi.fi-tagFamily-selected.fa-pull-left,
.fi.fi-tag-unselected.fa-pull-left,
.fi.fi-tag-selected.fa-pull-left,
.fi.fi-filter.fa-pull-left {
  margin-right: 0.3em;
}
.fi.fa-pull-right,
.fi.fi-square-o.fa-pull-right,
.fi.fi-map-o.fa-pull-right,
.fi.fi-trash.fa-pull-right,
.fi.fi-circle-o.fa-pull-right,
.fi.fi-gear.fa-pull-right,
.fi.fi-cog.fa-pull-right,
.fi.fi-gears.fa-pull-right,
.fi.fi-cogs.fa-pull-right,
.fi.fi-sign-in.fa-pull-right,
.fi.fi-sign-out.fa-pull-right,
.fi.fi-check.fa-pull-right,
.fi.fi-copy.fa-pull-right,
.fi.fi-save.fa-pull-right,
.fi.fi-error.fa-pull-right,
.fi.fi-arrow-left-stop.fa-pull-right,
.fi.fi-arrow-right-stop.fa-pull-right,
.fi.fi-arrow-up-stop.fa-pull-right,
.fi.fi-arrow-down-stop.fa-pull-right,
.fi.fi-file-o.fa-pull-right,
.fi.fi-file-excel-o.fa-pull-right,
.fi.fi-file-text-o.fa-pull-right,
.fi.fi-file-pdf-o.fa-pull-right,
.fi.fi-picture-o.fa-pull-right,
.fi.fi-user-o.fa-pull-right,
.fi.fi-mediaImage.fa-pull-right,
.fi.fi-mediaFile.fa-pull-right,
.fi.fi-media-pdf.fa-pull-right,
.fi.fi-media-svg.fa-pull-right,
.fi.fi-media-jpg.fa-pull-right,
.fi.fi-media-jpeg.fa-pull-right,
.fi.fi-media-png.fa-pull-right,
.fi.fi-media-gif.fa-pull-right,
.fi.fi-media-doc.fa-pull-right,
.fi.fi-media-docx.fa-pull-right,
.fi.fi-media-txt.fa-pull-right,
.fi.fi-tagFamily-unselected.fa-pull-right,
.fi.fi-tagFamily-selected.fa-pull-right,
.fi.fi-tag-unselected.fa-pull-right,
.fi.fi-tag-selected.fa-pull-right,
.fi.fi-filter.fa-pull-right {
  margin-left: 0.3em;
}
.fi.fi-newsletter-segment-segmentData-fieldType-text:before {
  content: "\f037";
}
.fi.fi-angle-left:before {
  content: "\f104";
}
.fi.fi-angle-right:before {
  content: "\f105";
}
.fi.fi-move-down:before {
  content: "\f358";
}
.fi.fi-move-left:before {
  content: "\f359";
}
.fi.fi-move-right:before {
  content: "\f35a";
}
.fi.fi-move-up:before {
  content: "\f35b";
}
.fi.fi-arrow-circle-down:before,
.fi.fi-lazyload:before,
.fi.fi-lazyload-images:before,
.fi.fi-lazyload-files:before {
  content: "\f0ab";
}
.fi.fi-arrow-circle-left:before {
  content: "\f0a8";
}
.fi.fi-arrow-circle-right:before {
  content: "\f0a9";
}
.fi.fi-arrow-circle-up:before {
  content: "\f0aa";
}
.fi.fi-arrow-down:before,
.fi.fi-arrow-down-stop:before,
.fi.fi-tagFamily-inherited:before {
  content: "\f063";
}
.fi.fi-arrow-left:before,
.fi.fi-arrow-left-stop:before {
  content: "\f060";
}
.fi.fi-arrow-right:before,
.fi.fi-arrow-right-stop:before {
  content: "\f061";
}
.fi.fi-arrow-up:before,
.fi.fi-arrow-up-stop:before {
  content: "\f062";
}
.fi.fi-full-screen:before,
.fi.fi-move-all:before {
  content: "\f0b2";
}
.fi.fi-book:before {
  content: "\f02d";
}
.fi.fi-calendar:before,
.fi.fi-newsletter-segment-segmentData-fieldType-date:before {
  content: "\f073";
}
.fi.fi-check:before,
.fi.fi-decisionComponent-submit:before,
.fi.fi-ok-confirm:before,
.fi.fi-decisionComponent-assign:before {
  content: "\f00c";
}
.fi.fi-check-circle:before,
.fi.fi-tagFamily-selected:before,
.fi.fi-tag-selected:before {
  content: "\f058";
}
.fi.fi-selectAll-media-image:before,
.fi.fi-selectAll-media-file:before {
  content: "\f14a";
}
.fi.fi-circle:before,
.fi.fi-circle-o:before,
.fi.fi-tagFamily-unselected:before,
.fi.fi-tag-unselected:before {
  content: "\f111";
}
.fi.fi-newsletter-segment-segmentData-fieldType-time:before {
  content: "\f017";
}
.fi.fi-masterComponents:before {
  content: "\f24d";
}
.fi.fi-gear:before,
.fi.fi-cog:before {
  content: "\f013";
}
.fi.fi-gears:before,
.fi.fi-cogs:before {
  content: "\f085";
}
.fi.fi-copy:before {
  content: "\f0c5";
}
.fi.fi-database:before {
  content: "\f1c0";
}
.fi.fi-download:before {
  content: "\f019";
}
.fi.fi-edit:before {
  content: "\f044";
}
.fi.fi-exclamation-circle:before,
.fi.fi-error:before {
  content: "\f06a";
}
.fi.fi-exclamation-triangle:before {
  content: "\f071";
}
.fi.fi-mediaFile:before {
  content: "\f15b";
}
.fi.fi-file:before,
.fi.fi-file-o:before,
.fi.fi-file-text-o:before,
.fi.fi-media-txt:before {
  content: "\f15c";
}
.fi.fi-file-excel-o:before {
  content: "\f1c3";
}
.fi.fi-file-image-o:before,
.fi.fi-media-svg:before,
.fi.fi-media-jpg:before,
.fi.fi-media-jpeg:before,
.fi.fi-media-png:before,
.fi.fi-media-gif:before {
  content: "\f1c5";
}
.fi.fi-file-pdf-o:before,
.fi.fi-media-pdf:before {
  content: "\f1c1";
}
.fi.fi-media-doc:before,
.fi.fi-media-docx:before {
  content: "\f1c2";
}
.fi.fi-filter:before,
.fi.fi-filter:before {
  content: "\f0b0";
}
.fi.fi-folder:before {
  content: "\f07b";
}
.fi.fi-hashtag:before {
  content: "\f292";
}
.fi.fi-home:before {
  content: "\f015";
}
.fi.fi-picture-o:before,
.fi.fi-mediaImage:before {
  content: "\f03e";
}
.fi.fi-infinity:before {
  content: "\f534";
}
.fi.fi-mediaImageDetails:before,
.fi.fi-mediaFileDetails:before {
  content: "\f05a";
}
.fi.fi-leaf:before {
  content: "\f06c";
}
.fi.fi-newsletter-segment-segmentData-fieldType-list:before {
  content: "\f03a";
}
.fi.fi-list-alt:before {
  content: "\f022";
}
.fi.fi-lock:before {
  content: "\f023";
}
.fi.fi-map-o:before {
  content: "\f279";
}
.fi.fi-minus:before {
  content: "\f068";
}
.fi.fi-minus-square:before {
  content: "\f146";
}
.fi.fi-pause:before {
  content: "\f04c";
}
.fi.fi-edit-slim:before {
  content: "\f303";
}
.fi.fi-plus:before {
  content: "\f067";
}
.fi.fi-plus-circle:before {
  content: "\f055";
}
.fi.fi-plus-square:before {
  content: "\f0fe";
}
.fi.fi-power-off:before {
  content: "\f011";
}
.fi.fi-newsletter-segment-segmentData-fieldType-boolean:before {
  content: "\f128";
}
.fi.fi-move:before {
  content: "\f074";
}
.fi.fi-save:before {
  content: "\f0c7";
}
.fi.fi-share-alt:before,
.fi.fi-assign:before,
.fi.fi-connect-language:before,
.fi.fi-connect-layout:before,
.fi.fi-select:before,
.fi.fi-assign-tags:before,
.fi.fi-upload-tagged:before {
  content: "\f1e0";
}
.fi.fi-sign-in:before,
.fi.fi-decisionComponent-signIn:before {
  content: "\f2f6";
}
.fi.fi-sign-out:before {
  content: "\f2f5";
}
.fi.fi-sort-desc:before {
  content: "\f0dd";
}
.fi.fi-newsletter-segment-segmentData-fieldType-number:before {
  content: "\f163";
}
.fi.fi-sort-asc:before {
  content: "\f0de";
}
.fi.fi-square-o:before,
.fi.fi-deselectAll-media-image:before,
.fi.fi-deselectAll-media-file:before {
  content: "\f0c8";
}
.fi.fi-image-new:before,
.fi.fi-file-new:before {
  content: "\f005";
}
.fi.fi-superpowers:before {
  content: "\f2dd";
}
.fi.fi-refresh:before {
  content: "\f2f1";
}
.fi.fi-tag:before {
  content: "\f02b";
}
.fi.fi-times:before,
.fi.fi-close-full-screen:before {
  content: "\f00d";
}
.fi.fi-trash:before {
  content: "\f2ed";
}
.fi.fi-upload:before {
  content: "\f093";
}
.fi.fi-user:before,
.fi.fi-user-o:before {
  content: "\f007";
}
.fi.fi-users:before {
  content: "\f0c0";
}
.fi.fi-window-close:before {
  content: "\f410";
}
.fi.fi-window-restore:before {
  content: "\f2d2";
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-circle-o,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-tagFamily-unselected,
.fi.fi-tagFamily-selected,
.fi.fi-tag-unselected,
.fi.fi-tag-selected {
  font-family: 'Font Awesome 5 Free';font-weight: 400;
}
.fi,
.fi.fi-trash,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-filter {
  font-family: 'Font Awesome 5 Free';font-weight: 900;
}
.fi.fi-arrow-left-stop {
  border-left: 0.125em solid;
}
.fi.fi-arrow-right-stop {
  border-right: 0.125em solid;
}
.fi.fi-arrow-up-stop {
  border-top: 0.125em solid;
}
.fi.fi-arrow-down-stop {
  border-bottom: 0.125em solid;
}
.fi.fi-picture-o {
  font-weight: 400 !important;
}
/* open-sans-regular - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.72a39de4cefe8de1cbee741d1257cb3b.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.72a39de4cefe8de1cbee741d1257cb3b.eot?#iefix) format('embedded-opentype'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.e7777b3c2bb7ae4d50f3abe9ee4f1eb5.woff2) format('woff2'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.54c038bebb956463f90f71a24f7efa92.woff) format('woff'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.9ccd5e1b1dbea150336d10cec14bc0d6.ttf) format('truetype'),  url(../images/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.77786a1b5681506a4a372f2b9ae61bc4.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.d8404d8cdf3b92cb043fba1a74ab9b88.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.d8404d8cdf3b92cb043fba1a74ab9b88.eot?#iefix) format('embedded-opentype'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.098c0a7547a49b0ce57658f41c897ecd.woff2) format('woff2'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.c98e15842263b404f6b7533da0523e48.woff) format('woff'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.d31d0aaa8d4384de5eeb85053fa1bc54.ttf) format('truetype'),  url(../images/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.c6b0bad4257e19c40dc29498cf81d5bb.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700 - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.81c1fea707519665682c86ce06bb06d0.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.81c1fea707519665682c86ce06bb06d0.eot?#iefix) format('embedded-opentype'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.55108f7b45c93a78893b760b940e2d19.woff2) format('woff2'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.357375ac4bf5dda40f834c15f28bba3f.woff) format('woff'),  url(../fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.fc10ccf11a61bac919b98aba11c990eb.ttf) format('truetype'),  url(../images/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.c537d95794e4189d6e71f5d46702d122.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
.fi,
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-tagFamily-unselected,
.fi.fi-tagFamily-selected,
.fi.fi-tag-unselected,
.fi.fi-tag-selected,
.fi.fi-filter {
  -moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;display: inline-block;font-style: normal;font-variant: normal;text-rendering: auto;line-height: 1;
}
.fi.fa-pull-left,
.fi.fi-square-o.fa-pull-left,
.fi.fi-map-o.fa-pull-left,
.fi.fi-trash.fa-pull-left,
.fi.fi-circle-o.fa-pull-left,
.fi.fi-gear.fa-pull-left,
.fi.fi-cog.fa-pull-left,
.fi.fi-gears.fa-pull-left,
.fi.fi-cogs.fa-pull-left,
.fi.fi-sign-in.fa-pull-left,
.fi.fi-sign-out.fa-pull-left,
.fi.fi-check.fa-pull-left,
.fi.fi-copy.fa-pull-left,
.fi.fi-save.fa-pull-left,
.fi.fi-error.fa-pull-left,
.fi.fi-arrow-left-stop.fa-pull-left,
.fi.fi-arrow-right-stop.fa-pull-left,
.fi.fi-arrow-up-stop.fa-pull-left,
.fi.fi-arrow-down-stop.fa-pull-left,
.fi.fi-file-o.fa-pull-left,
.fi.fi-file-excel-o.fa-pull-left,
.fi.fi-file-text-o.fa-pull-left,
.fi.fi-file-pdf-o.fa-pull-left,
.fi.fi-picture-o.fa-pull-left,
.fi.fi-user-o.fa-pull-left,
.fi.fi-mediaImage.fa-pull-left,
.fi.fi-mediaFile.fa-pull-left,
.fi.fi-media-pdf.fa-pull-left,
.fi.fi-media-svg.fa-pull-left,
.fi.fi-media-jpg.fa-pull-left,
.fi.fi-media-jpeg.fa-pull-left,
.fi.fi-media-png.fa-pull-left,
.fi.fi-media-gif.fa-pull-left,
.fi.fi-media-doc.fa-pull-left,
.fi.fi-media-docx.fa-pull-left,
.fi.fi-media-txt.fa-pull-left,
.fi.fi-tagFamily-unselected.fa-pull-left,
.fi.fi-tagFamily-selected.fa-pull-left,
.fi.fi-tag-unselected.fa-pull-left,
.fi.fi-tag-selected.fa-pull-left,
.fi.fi-filter.fa-pull-left {
  margin-right: 0.3em;
}
.fi.fa-pull-right,
.fi.fi-square-o.fa-pull-right,
.fi.fi-map-o.fa-pull-right,
.fi.fi-trash.fa-pull-right,
.fi.fi-circle-o.fa-pull-right,
.fi.fi-gear.fa-pull-right,
.fi.fi-cog.fa-pull-right,
.fi.fi-gears.fa-pull-right,
.fi.fi-cogs.fa-pull-right,
.fi.fi-sign-in.fa-pull-right,
.fi.fi-sign-out.fa-pull-right,
.fi.fi-check.fa-pull-right,
.fi.fi-copy.fa-pull-right,
.fi.fi-save.fa-pull-right,
.fi.fi-error.fa-pull-right,
.fi.fi-arrow-left-stop.fa-pull-right,
.fi.fi-arrow-right-stop.fa-pull-right,
.fi.fi-arrow-up-stop.fa-pull-right,
.fi.fi-arrow-down-stop.fa-pull-right,
.fi.fi-file-o.fa-pull-right,
.fi.fi-file-excel-o.fa-pull-right,
.fi.fi-file-text-o.fa-pull-right,
.fi.fi-file-pdf-o.fa-pull-right,
.fi.fi-picture-o.fa-pull-right,
.fi.fi-user-o.fa-pull-right,
.fi.fi-mediaImage.fa-pull-right,
.fi.fi-mediaFile.fa-pull-right,
.fi.fi-media-pdf.fa-pull-right,
.fi.fi-media-svg.fa-pull-right,
.fi.fi-media-jpg.fa-pull-right,
.fi.fi-media-jpeg.fa-pull-right,
.fi.fi-media-png.fa-pull-right,
.fi.fi-media-gif.fa-pull-right,
.fi.fi-media-doc.fa-pull-right,
.fi.fi-media-docx.fa-pull-right,
.fi.fi-media-txt.fa-pull-right,
.fi.fi-tagFamily-unselected.fa-pull-right,
.fi.fi-tagFamily-selected.fa-pull-right,
.fi.fi-tag-unselected.fa-pull-right,
.fi.fi-tag-selected.fa-pull-right,
.fi.fi-filter.fa-pull-right {
  margin-left: 0.3em;
}
.fi.fi-newsletter-segment-segmentData-fieldType-text:before {
  content: "\f037";
}
.fi.fi-angle-left:before {
  content: "\f104";
}
.fi.fi-angle-right:before {
  content: "\f105";
}
.fi.fi-move-down:before {
  content: "\f358";
}
.fi.fi-move-left:before {
  content: "\f359";
}
.fi.fi-move-right:before {
  content: "\f35a";
}
.fi.fi-move-up:before {
  content: "\f35b";
}
.fi.fi-arrow-circle-down:before,
.fi.fi-lazyload:before,
.fi.fi-lazyload-images:before,
.fi.fi-lazyload-files:before {
  content: "\f0ab";
}
.fi.fi-arrow-circle-left:before {
  content: "\f0a8";
}
.fi.fi-arrow-circle-right:before {
  content: "\f0a9";
}
.fi.fi-arrow-circle-up:before {
  content: "\f0aa";
}
.fi.fi-arrow-down:before,
.fi.fi-arrow-down-stop:before,
.fi.fi-tagFamily-inherited:before {
  content: "\f063";
}
.fi.fi-arrow-left:before,
.fi.fi-arrow-left-stop:before {
  content: "\f060";
}
.fi.fi-arrow-right:before,
.fi.fi-arrow-right-stop:before {
  content: "\f061";
}
.fi.fi-arrow-up:before,
.fi.fi-arrow-up-stop:before {
  content: "\f062";
}
.fi.fi-full-screen:before,
.fi.fi-move-all:before {
  content: "\f0b2";
}
.fi.fi-book:before {
  content: "\f02d";
}
.fi.fi-calendar:before,
.fi.fi-newsletter-segment-segmentData-fieldType-date:before {
  content: "\f073";
}
.fi.fi-check:before,
.fi.fi-decisionComponent-submit:before,
.fi.fi-ok-confirm:before,
.fi.fi-decisionComponent-assign:before {
  content: "\f00c";
}
.fi.fi-check-circle:before,
.fi.fi-tagFamily-selected:before,
.fi.fi-tag-selected:before {
  content: "\f058";
}
.fi.fi-selectAll-media-image:before,
.fi.fi-selectAll-media-file:before {
  content: "\f14a";
}
.fi.fi-circle:before,
.fi.fi-circle-o:before,
.fi.fi-tagFamily-unselected:before,
.fi.fi-tag-unselected:before {
  content: "\f111";
}
.fi.fi-newsletter-segment-segmentData-fieldType-time:before {
  content: "\f017";
}
.fi.fi-masterComponents:before {
  content: "\f24d";
}
.fi.fi-gear:before,
.fi.fi-cog:before {
  content: "\f013";
}
.fi.fi-gears:before,
.fi.fi-cogs:before {
  content: "\f085";
}
.fi.fi-copy:before {
  content: "\f0c5";
}
.fi.fi-database:before {
  content: "\f1c0";
}
.fi.fi-download:before {
  content: "\f019";
}
.fi.fi-edit:before {
  content: "\f044";
}
.fi.fi-exclamation-circle:before,
.fi.fi-error:before {
  content: "\f06a";
}
.fi.fi-exclamation-triangle:before {
  content: "\f071";
}
.fi.fi-mediaFile:before {
  content: "\f15b";
}
.fi.fi-file:before,
.fi.fi-file-o:before,
.fi.fi-file-text-o:before,
.fi.fi-media-txt:before {
  content: "\f15c";
}
.fi.fi-file-excel-o:before {
  content: "\f1c3";
}
.fi.fi-file-image-o:before,
.fi.fi-media-svg:before,
.fi.fi-media-jpg:before,
.fi.fi-media-jpeg:before,
.fi.fi-media-png:before,
.fi.fi-media-gif:before {
  content: "\f1c5";
}
.fi.fi-file-pdf-o:before,
.fi.fi-media-pdf:before {
  content: "\f1c1";
}
.fi.fi-media-doc:before,
.fi.fi-media-docx:before {
  content: "\f1c2";
}
.fi.fi-filter:before,
.fi.fi-filter:before {
  content: "\f0b0";
}
.fi.fi-folder:before {
  content: "\f07b";
}
.fi.fi-hashtag:before {
  content: "\f292";
}
.fi.fi-home:before {
  content: "\f015";
}
.fi.fi-picture-o:before,
.fi.fi-mediaImage:before {
  content: "\f03e";
}
.fi.fi-infinity:before {
  content: "\f534";
}
.fi.fi-mediaImageDetails:before,
.fi.fi-mediaFileDetails:before {
  content: "\f05a";
}
.fi.fi-leaf:before {
  content: "\f06c";
}
.fi.fi-newsletter-segment-segmentData-fieldType-list:before {
  content: "\f03a";
}
.fi.fi-list-alt:before {
  content: "\f022";
}
.fi.fi-lock:before {
  content: "\f023";
}
.fi.fi-map-o:before {
  content: "\f279";
}
.fi.fi-minus:before {
  content: "\f068";
}
.fi.fi-minus-square:before {
  content: "\f146";
}
.fi.fi-pause:before {
  content: "\f04c";
}
.fi.fi-edit-slim:before {
  content: "\f303";
}
.fi.fi-plus:before {
  content: "\f067";
}
.fi.fi-plus-circle:before {
  content: "\f055";
}
.fi.fi-plus-square:before {
  content: "\f0fe";
}
.fi.fi-power-off:before {
  content: "\f011";
}
.fi.fi-newsletter-segment-segmentData-fieldType-boolean:before {
  content: "\f128";
}
.fi.fi-move:before {
  content: "\f074";
}
.fi.fi-save:before {
  content: "\f0c7";
}
.fi.fi-share-alt:before,
.fi.fi-assign:before,
.fi.fi-connect-language:before,
.fi.fi-connect-layout:before,
.fi.fi-select:before,
.fi.fi-assign-tags:before,
.fi.fi-upload-tagged:before {
  content: "\f1e0";
}
.fi.fi-sign-in:before,
.fi.fi-decisionComponent-signIn:before {
  content: "\f2f6";
}
.fi.fi-sign-out:before {
  content: "\f2f5";
}
.fi.fi-sort-desc:before {
  content: "\f0dd";
}
.fi.fi-newsletter-segment-segmentData-fieldType-number:before {
  content: "\f163";
}
.fi.fi-sort-asc:before {
  content: "\f0de";
}
.fi.fi-square-o:before,
.fi.fi-deselectAll-media-image:before,
.fi.fi-deselectAll-media-file:before {
  content: "\f0c8";
}
.fi.fi-image-new:before,
.fi.fi-file-new:before {
  content: "\f005";
}
.fi.fi-superpowers:before {
  content: "\f2dd";
}
.fi.fi-refresh:before {
  content: "\f2f1";
}
.fi.fi-tag:before {
  content: "\f02b";
}
.fi.fi-times:before,
.fi.fi-close-full-screen:before {
  content: "\f00d";
}
.fi.fi-trash:before {
  content: "\f2ed";
}
.fi.fi-upload:before {
  content: "\f093";
}
.fi.fi-user:before,
.fi.fi-user-o:before {
  content: "\f007";
}
.fi.fi-users:before {
  content: "\f0c0";
}
.fi.fi-window-close:before {
  content: "\f410";
}
.fi.fi-window-restore:before {
  content: "\f2d2";
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-circle-o,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-tagFamily-unselected,
.fi.fi-tagFamily-selected,
.fi.fi-tag-unselected,
.fi.fi-tag-selected {
  font-family: 'Font Awesome 5 Free';font-weight: 400;
}
.fi,
.fi.fi-trash,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-filter {
  font-family: 'Font Awesome 5 Free';font-weight: 900;
}
