.templates_mediaManagement_imageAssignmentDetails_renderedImageList {
  position: relative;
  width: 100%;
  height: 100%;
}
.templates_mediaManagement_imageAssignmentDetails_renderedImageList .deviceContainer,
.templates_mediaManagement_imageAssignmentDetails_renderedImageList .breakPointContainer {
  position: relative;
  float: left;
  width: 100%;
  border-top: 1px solid gray;
  margin-top: 10px;
  padding: 12px 0;
  box-sizing: border-box;
}
.templates_mediaManagement_imageAssignmentDetails_renderedImageList .deviceContainer .containerTitle,
.templates_mediaManagement_imageAssignmentDetails_renderedImageList .breakPointContainer .containerTitle {
  position: relative;
  float: left;
  margin: -22px 30px -22px;
  padding: 0 10px;
  background-color: #F9F9F9;
}
.templates_mediaManagement_imageAssignmentDetails_renderedImageList .breakPointContainer {
  padding: 12px 20px;
}
