.templates_mediaManagement_mediaDetails_mediaInfoField,
.base_form_form .cols .col .field > .templates_mediaManagement_mediaDetails_mediaInfoField:not(.fieldIndependent) {
  width: 100% !important;
  float: unset;
  padding-bottom: 0.5em !important;
  margin-bottom: 0.5em !important;
  border-bottom: 1px solid #CCCCCC !important;
}
.templates_mediaManagement_mediaDetails_mediaInfoField > .thumb,
.base_form_form .cols .col .field > .templates_mediaManagement_mediaDetails_mediaInfoField:not(.fieldIndependent) > .thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 110px;
  min-height: 110px;
  box-sizing: border-box;
  padding: 5px;
  margin: 0 0.5em 0.5em 0;
  background-color: #faebd7;
  border: 1px solid #808080;
}
.templates_mediaManagement_mediaDetails_mediaInfoField > .thumb > img,
.base_form_form .cols .col .field > .templates_mediaManagement_mediaDetails_mediaInfoField:not(.fieldIndependent) > .thumb > img {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}
.templates_mediaManagement_mediaDetails_mediaInfoField > table,
.base_form_form .cols .col .field > .templates_mediaManagement_mediaDetails_mediaInfoField:not(.fieldIndependent) > table {
  margin-bottom: 0.5em;
}
.templates_mediaManagement_mediaDetails_mediaInfoField > ul,
.base_form_form .cols .col .field > .templates_mediaManagement_mediaDetails_mediaInfoField:not(.fieldIndependent) > ul {
  display: none;
}
