.templates_controller_controller {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  font-size: 12px;
}
.templates_controller_controller .showOnLoggedIn {
  display: none !important;
}
.templates_controller_controller .showOnLoggedOut {
  display: unset !important;
}
.templates_controller_controller.loggedIn .showOnLoggedIn {
  display: unset !important;
}
.templates_controller_controller.loggedIn .showOnLoggedOut {
  display: none !important;
}
.templates_controller_controller > .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.25em;
  border-bottom: 1px solid #9C9C9C;
  padding: 0.25em 0.5em 0.325em 0.5em;
  margin-bottom: 0.5em;
  box-sizing: border-box;
}
.templates_controller_controller > .head i {
  color: #5B7DAD;
}
.templates_controller_controller > .head i:hover {
  color: #47648e;
}
.templates_controller_controller > .head > h1 {
  margin: 0;
  width: 100%;
  text-align: center;
  font-weight: 400;
}
.templates_controller_controller > .head > .button {
  font-size: 2em;
}
.templates_controller_controller > .head > .button:hover {
  cursor: pointer;
  color: #9C9C9C;
}
.templates_controller_controller > .head > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.templates_controller_controller > section.filter {
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.5em;
}
.templates_controller_controller > section.list {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  font-size: 1.5em;
}
.templates_controller_controller > .footer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.templates_controller_controller > .footer a {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 0 0.75em;
  text-decoration: none;
  color: #333333;
}
.templates_controller_controller > .footer a img {
  display: flex;
  height: 25px;
  width: 117px;
  margin: 0.25em auto;
}
body:not(.fullScreen) .templates_controller_controller {
  padding: 0;
}
body:not(.fullScreen) .templates_controller_controller > .head .button.login,
body:not(.fullScreen) .templates_controller_controller > .head .button.logout {
  display: none !important;
}
body.device-phone .templates_controller_controller > .head {
  font-size: 0.875em !important;
}
