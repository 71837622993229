.base_form_form.templates_mediaManagement_actionsForm h1 {
  font-size: calc(0.35em + 1vmin);
}
.base_form_form.templates_mediaManagement_actionsForm button,
.base_form_form.templates_mediaManagement_actionsForm button.negative {
  width: 100%;
  float: left;
  clear: left;
  margin: 0 0 0 10px;
  background-color: transparent !important;
  border: none;
  color: #572C00 !important;
  text-align: left;
  font-size: calc(0.31em + 1vmin);
  padding: 5px 10px;
}
.base_form_form.templates_mediaManagement_actionsForm button:hover,
.base_form_form.templates_mediaManagement_actionsForm button.negative:hover {
  color: #000000;
  background-color: transparent;
}
.base_form_form.templates_mediaManagement_actionsForm .cols .col .field > *:not(.fieldIndependent) {
  width: 100% !important;
}
