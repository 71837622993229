.templates_mediaManagement_upload_plupload_plupload {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}
.templates_mediaManagement_upload_plupload_plupload button.upload {
  width: inherit;
  padding: 10px;
  line-height: 135%;
}
.templates_mediaManagement_upload_plupload_plupload button.upload.taggedUpload {
  margin-top: 20px;
}
.templates_mediaManagement_upload_plupload_plupload button.upload:hover {
  cursor: pointer;
}
.templates_mediaManagement_upload_plupload_plupload .progress {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 20px;
  margin: 20px 0 0 0;
  border-radius: 5px;
  border: 1px solid #333;
  overflow: hidden;
  opacity: 0.1;
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
  transition-delay: 0.1s;
}
.templates_mediaManagement_upload_plupload_plupload .progress.inProgress {
  opacity: 1;
}
.templates_mediaManagement_upload_plupload_plupload .progress .background {
  position: relative;
  width: 0;
  height: 100%;
  background-color: lightblue;
}
.templates_mediaManagement_upload_plupload_plupload .progress .content {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: -20px;
  padding: 3px;
  text-align: center;
  color: #333;
  font-size: 14px;
}
.templates_mediaManagement_upload_plupload_plupload .uploads {
  display: none;
}
