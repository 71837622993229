.templates_login_container {
  float: none;
  width: 80vw;
  background-color: #F9F9F9;
  border-radius: 0.5em;
  border: 1px solid #CCCCCC;
  overflow: hidden;
  padding: 2vh;
  margin: 10vh auto;
}
.templates_login_container .buttons {
  margin-top: 4vh;
  padding-bottom: 0;
}
.templates_login_container button[data-field-type=submit],
.templates_login_container button[data-field-type=submit]:hover,
.templates_login_container button[data-field-type=submit]:focus {
  background-color: #572C00;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.templates_login_container .base_tabStrip_tabStrip section.tabContents {
  height: initial;
}
.templates_login_container .base_tabStrip_tabStrip section.tabContents .tabContent {
  height: initial;
}
@media (min-width: 600px) {
  .templates_login_container {
    width: 60vw;
  }
}
@media (min-width: 1000px) {
  .templates_login_container {
    width: 500px;
  }
}
.base_modal_modal .templates_login_container {
  height: initial;
  background-color: #F9F9F9;
  border: none;
  padding: 0;
  margin: 4% auto;
}
body.mobile .templates_login_loginForm label,
body.mobile .templates_login_loginForm input {
  font-size: 2vh !important;
}
