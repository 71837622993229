.templates_mediaManagement_file_nrOfElements {
  margin-top: auto;
  box-sizing: border-box;
}
.templates_mediaManagement_file_nrOfElements > table {
  width: 100%;
  font-size: 0.875em;
  font-style: italic;
}
.templates_mediaManagement_file_nrOfElements > table > tbody > tr > td {
  text-align: left;
}
.templates_mediaManagement_file_nrOfElements > table > tbody > tr > td:last-of-type {
  text-align: right;
}
