.templates_mediaManagement_file_fileList {
  display: flex;
  flex-direction: column;
}
.templates_mediaManagement_file_fileList > .fileList {
  width: 100%;
}
.templates_mediaManagement_file_fileList > .newFiles {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.templates_mediaManagement_file_fileList > .newFiles > .title {
  font-size: 1em;
  border-bottom: 1px solid #808080;
  margin-bottom: 12px;
  padding: 0 0 3px 12px;
  font-style: italic;
}
.templates_mediaManagement_file_fileList > .newFiles > .newFileList {
  width: 100%;
  margin-bottom: 12px;
  padding: 0 25px 12px 25px;
  box-sizing: border-box;
  border-bottom: 1px solid #808080;
}
.templates_mediaManagement_file_fileList > .newFiles > * {
  display: none;
}
.templates_mediaManagement_file_fileList > .newFiles.active > * {
  display: block;
}
.templates_mediaManagement_file_fileList > .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 0.75em;
}
.templates_mediaManagement_file_fileList > .buttons > button {
  font-size: 1.125em;
}
.templates_mediaManagement_file_fileList > .buttons > button > i {
  font-size: 1.125em;
  margin-right: 0.25em;
}
